import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import Card from '~/components/CardContent';
import SuggestCard from '~/components/SuggestCard';
import { getNewsByCategory } from '~/services/newsService';
import styles from './News.module.scss';
import Title from '~/components/Title';
import ButtonGroup from '~/components/ButtonGroup';
import PushNotification from '~/components/PushNotification';
import LoadingScreen from '~/components/LoadingScreen';
import routes from '~/config/routes';
import { getCategoriesByType } from '~/services/categoryService';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import 'swiper/css';
import 'swiper/css/autoplay';

const cx = classNames.bind(styles);

const News = () => {
    const [newsItems, setNewsItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [groupedNews, setGroupedNews] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedSuggestion, setSelectedSuggestion] = useState(0);

    useEffect(() => {
        const fetchCategoriesAndNews = async () => {
            try {
                const categoriesData = await getCategoriesByType(4);
                setCategories(categoriesData);

                const groupedNewsMap = {};

                await Promise.all(
                    categoriesData.map(async (category) => {
                        const newsData = await getNewsByCategory(category._id);
                        if (newsData.news.length > 0) {
                            groupedNewsMap[category._id] = newsData.news.map((item) => ({
                                ...item,
                                image: item.images,
                                isNew: dayjs().diff(dayjs(item.createdAt), 'day') <= 3,
                            }));
                        }
                    }),
                );

                setGroupedNews(groupedNewsMap);
                setNewsItems(Object.values(groupedNewsMap).flat());
            } catch (error) {
                setError(error);
                console.error('Error fetching news:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategoriesAndNews();
    }, []);

    const handleButtonClick = (index) => {
        setSelectedSuggestion(index);
    };

    const getCategorySlug = (categoryId) => {
        const category = categories.find((category) => categoryId === category._id);
        return category ? category.slug : '';
    };

    if (error) {
        const errorMessage = error.response ? error.response.data.message : 'Network Error';
        return <PushNotification message={errorMessage} />;
    }

    if (loading) {
        return <LoadingScreen />;
    }

    const filteredNewsItems = newsItems
        .filter((item) => {
            if (selectedSuggestion === 0) {
                return item.isFeatured;
            }
            if (selectedSuggestion === 1) {
                return item.views > 10;
            }
            return true;
        })
        .slice(0, 5);

    return (
        <article className={cx('wrapper')}>
            <Helmet>
                <title>Tin Tức | TDC DAKLAK</title>
                <meta
                    name="description"
                    content="Trung tâm Kỹ thuật Tiêu chuẩn Đo lường Chất lượng tỉnh Đắk Lắk là đơn vị được chỉ định cung cấp các dịch vụ Kiểm định, Hiệu chuẩn, Thử nghiệm phương tiện đo, Phân tích thử nghiệm chất lượng sản phẩm hàng hóa phục vụ quản lý nhà nước và nhu cầu cho các tổ chức, cá nhân trong và ngoài tỉnh."
                />
                <meta name="keywords" content="tin tức, cập nhật, TDC DAKLAK" />
            </Helmet>
            <div className={cx('news-section')}>
                <div className={cx('news-column')}>
                    <h2 className={cx('news-title')}>Tin Tức</h2>
                    {categories
                        .filter((category) => groupedNews[category._id] && groupedNews[category._id].length > 0)
                        .map((category) => {
                            const slides = groupedNews[category._id]?.slice(0, 6) || [];
                            const shouldLoop = slides.length > 3;

                            return (
                                <div key={category._id} className={cx('news-category')}>
                                    <Title
                                        text={category.name || 'Loading...'}
                                        showSeeAll={true}
                                        slug={`${routes.news}/${category.slug}`}
                                        categoryId={category._id}
                                    />
                                    <Swiper
                                        spaceBetween={10}
                                        slidesPerView={3}
                                        breakpoints={{
                                            1280: { slidesPerView: 3 },
                                            1024: { slidesPerView: 3 },
                                            768: { slidesPerView: 2 },
                                            0: { slidesPerView: 1 },
                                        }}
                                        loop={shouldLoop}
                                        modules={[Autoplay]}
                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: false,
                                        }}
                                    >
                                        {slides.map((item, index) => (
                                            <SwiperSlide key={index} className={cx('slide')}>
                                                <Link to={`${routes.news}/${category.slug}/${item._id}`}>
                                                    <Card
                                                        title={item.title}
                                                        summary={item.summary}
                                                        image={`${process.env.REACT_APP_HOST}/${item.images}`}
                                                        createdAt={item.createdAt}
                                                        views={item.views}
                                                        isNew={item.isNew} // Pass isNew prop
                                                    />
                                                </Link>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            );
                        })}
                </div>
                <div className={cx('suggest')}>
                    <h2 className={cx('suggest-title')}>Có thể bạn quan tâm</h2>
                    <ButtonGroup buttons={['Nổi bật', 'Xem nhiều']} onButtonClick={handleButtonClick} />
                    <div className={cx('suggest-items')}>
                        {filteredNewsItems.map((item, index) => (
                            <Link key={index} to={`${routes.news}/${getCategorySlug(item.categoryId)}/${item._id}`}>
                                <SuggestCard
                                    title={item.title}
                                    summary={item.summary}
                                    image={`${process.env.REACT_APP_HOST}/${item.images}`}
                                    createdAt={item.createdAt}
                                    views={item.views}
                                    isNew={item.isNew} // Pass isNew prop
                                />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </article>
    );
};

export default News;
