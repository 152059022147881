import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe,  faPhone, faArrowTrendUp} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import styles from './Tophead.module.scss';
import { Link } from 'react-router-dom';
import { getConfiguration } from '~/services/configurationService';


const cx = classNames.bind(styles);

const Tophead = () => {
    const [hotlines, setHotlines] = useState([]);

    useEffect(() => {
        const fetchConfig = async () => {
            const config = await getConfiguration();
            if (config) {
                setHotlines([{ number: config.hotline.toString(), name: '' }]);
            }
        };
        fetchConfig();
    }, []);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('inner')}>
                <div className={cx('hotline-container')}>
                    <div className={cx('hotline')}>
                        <span className={cx('hotline-label')}>
                            <FontAwesomeIcon icon={faPhone} className={cx('phone-icon')} />
                            Hotline:
                        </span>
                        <div className={cx('hotline-numbers')}>
                            {hotlines.map((hotline, index) => (
                                <React.Fragment key={index}>
                                    {index > 0 && <span className={cx('separator')}> - </span>}
                                    <a className={cx('hotline-number')} href={`tel:0${hotline.number}`}>
                                        0{hotline.number} {hotline.name && `(${hotline.name})`}
                                    </a>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
                <Link to="https://kiemdinhdaklak.vn/login" className={cx('button-login')}>
                    <FontAwesomeIcon icon={faGlobe} className={cx('login-icon')} />
                </Link>
                <Link to="https://app.kiemdinhdaklak.vn/" className={cx('button-login')}>
                    <FontAwesomeIcon icon={faArrowTrendUp} className={cx('login-icon')} />
                </Link>
            </div>
        </div>
    );
};

export default Tophead;
