import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { getConfiguration, updateConfiguration } from '~/services/configurationService';
import PushNotification from '~/components/PushNotification';
import Title from '~/components/Title';
import styles from './Settings.module.scss';

const Settings = () => {
    const [settings, setSettings] = useState({
        name: '',
        homepage_slider: [],
        contact_email: '',
        phone_number: [],
        hotline: '',
        address: '',
        socialLink: [],
        logo: '',
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [notification, setNotification] = useState({ message: '', type: '' });

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const data = await getConfiguration();
                console.log(data);
                setSettings({
                    ...data,
                });
            } catch (error) {
                setError('Failed to fetch settings.');
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleRemoveSlide = (index) => {
        const updatedSlider = settings.homepage_slider.filter((_, i) => i !== index);
        setSettings((prevSettings) => ({
            ...prevSettings,
            homepage_slider: updatedSlider,
        }));
    };

    const handleAddSlide = () => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            homepage_slider: [...prevSettings.homepage_slider, { image_url: '' }],
        }));
    };

    const handlePhoneNumberChange = (index, e) => {
        const updatedPhoneNumbers = settings.phone_number.map((number, i) => (i === index ? e.target.value : number));
        setSettings((prevSettings) => ({
            ...prevSettings,
            phone_number: updatedPhoneNumbers,
        }));
    };

    const handleAddPhoneNumber = () => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            phone_number: [...prevSettings.phone_number, ''],
        }));
    };

    const handleRemovePhoneNumber = (index) => {
        const updatedPhoneNumbers = settings.phone_number.filter((_, i) => i !== index);
        setSettings((prevSettings) => ({
            ...prevSettings,
            phone_number: updatedPhoneNumbers,
        }));
    };

    const handleSocialLinkChange = (index, e) => {
        const { name, value } = e.target;
        const updatedSocialLinks = settings.socialLink.map((link, i) =>
            i === index ? { ...link, [name]: value } : link,
        );
        setSettings((prevSettings) => ({
            ...prevSettings,
            socialLink: updatedSocialLinks,
        }));
    };

    const onDropSliderImage = useCallback(
        (index, acceptedFiles) => {
            const file = acceptedFiles[0];
            const newImageUrl = URL.createObjectURL(file); // Create a blob URL for the new image

            const updatedSlider = settings.homepage_slider.map((slide, i) =>
                i === index ? { ...slide, image_url: newImageUrl, image_file: file } : slide,
            );

            setSettings((prevSettings) => ({
                ...prevSettings,
                homepage_slider: updatedSlider,
            }));
        },
        [settings.homepage_slider],
    );

    const onDropLogo = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const newLogoUrl = URL.createObjectURL(file);
        setSettings((prevSettings) => ({
            ...prevSettings,
            logo: newLogoUrl,
            logoFile: file, // Store the file separately
        }));
    }, []);

    const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({
        onDrop: onDropLogo,
        multiple: false,
        accept: 'image/*',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        console.log(settings);

        // Add settings fields to formData
        formData.append('name', settings.name);
        formData.append('contact_email', settings.contact_email);
        formData.append('hotline', settings.hotline);
        formData.append('address', settings.address);

        // Add phone numbers and social links as JSON strings
        formData.append('phone_number', JSON.stringify(settings.phone_number));
        formData.append('socialLink', JSON.stringify(settings.socialLink));

        // Add logo
        if (settings.logo.startsWith('blob:')) {
            // If the logo is a new file, append it as a binary file
            if (settings.logoFile) {
                formData.append('logo', settings.logoFile);
            }
        } else {
            // If the logo is an existing URL, append it as a string
            formData.append('logo', settings.logo);
        }

        // Prepare homepage slider data
        const homepageSliderData = settings.homepage_slider
            .map((slide) => {
                if (slide.image_file) {
                    // Append new image files as binary with the field name 'image'
                    formData.append('image', slide.image_file);
                    return null; // New images are handled separately
                } else {
                    // Return existing image data
                    return { id: slide.id, image_url: slide.image_url };
                }
            })
            .filter((slide) => slide !== null); // Filter out null values

        // Append the homepage slider data as a JSON string
        formData.append('homepage_slider', JSON.stringify(homepageSliderData));

        try {
            await updateConfiguration(formData, settings._id);
            setNotification({ message: 'Settings updated successfully!', type: 'success' });
        } catch (error) {
            setNotification({ message: 'Failed to update settings.', type: 'error' });
        }
    };

    const SliderItem = ({ slide, index, onDropSliderImage, handleRemoveSlide }) => {
        const { getRootProps, getInputProps } = useDropzone({
            onDrop: (acceptedFiles) => onDropSliderImage(index, acceptedFiles),
            multiple: false,
            accept: 'image/*',
        });

        if (loading) return <div>Loading...</div>;
        if (error) return <div>{error}</div>;

        return (
            <div className={styles.slideItem}>
                <div className={styles.formGroup}>
                    <label>Hình ảnh</label>
                    <div {...getRootProps({ className: styles.dropzone })}>
                        <input {...getInputProps()} />
                        <p>Kéo và thả tệp, hoặc nhấp để chọn tệp</p>
                    </div>
                    {slide.image_url && (
                        <img
                            src={
                                slide.image_url.startsWith('blob:')
                                    ? slide.image_url
                                    : `${process.env.REACT_APP_HOST}/${slide.image_url}`
                            }
                            alt={`Slide ${index}`}
                            className={styles.previewImage}
                        />
                    )}
                </div>
                <button type="button" onClick={() => handleRemoveSlide(index)} className={styles.removeButton}>
                    Xóa
                </button>
            </div>
        );
    };

    return (
        <div className={styles.settingsContainer}>
            <Title className={styles.pageTitle} text="Cài đặt chung" />
            {notification.message && <PushNotification message={notification.message} type={notification.type} />}
            <form onSubmit={handleSubmit} className={styles.settingsForm}>
                <div className={styles.formGroup}>
                    <label htmlFor="name">Tên công ty</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={settings.name}
                        onChange={handleChange}
                        className={styles.formControl}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label>Logo</label>
                    {settings.logo && (
                        <img
                            src={
                                settings.logo.startsWith('blob:')
                                    ? settings.logo
                                    : `${process.env.REACT_APP_HOST}/${settings.logo}`
                            }
                            alt="Logo"
                            className={styles.previewImageLogo}
                        />
                    )}
                    <div {...getLogoRootProps({ className: styles.dropzone })}>
                        <input {...getLogoInputProps()} />
                        <p>Kéo và thả tệp, hoặc nhấp để chọn tệp</p>
                    </div>
                </div>

                <div className={styles.formGroup}>
                    <label>Slider Trang chủ</label>
                    {settings.homepage_slider.map((slide, index) => (
                        <SliderItem
                            key={index}
                            slide={slide}
                            index={index}
                            onDropSliderImage={onDropSliderImage}
                            handleRemoveSlide={handleRemoveSlide}
                        />
                    ))}
                    <button type="button" onClick={handleAddSlide} className={styles.addButton}>
                        Thêm slide
                    </button>
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="contact_email">Email liên hệ</label>
                    <input
                        type="email"
                        id="contact_email"
                        name="contact_email"
                        value={settings.contact_email}
                        onChange={handleChange}
                        className={styles.formControl}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Số điện thoại</label>
                    {settings.phone_number.map((number, index) => (
                        <div key={index} className={styles.phoneNumberGroup}>
                            <input
                                type="text"
                                value={number}
                                onChange={(e) => handlePhoneNumberChange(index, e)}
                                className={styles.phoneFormControl}
                            />
                            <button
                                type="button"
                                onClick={() => handleRemovePhoneNumber(index)}
                                className={styles.removeButton}
                            >
                                Xóa số
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddPhoneNumber} className={styles.addButton}>
                        Thêm số điện thoại
                    </button>
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="hotline">Hotline</label>
                    <input
                        type="text"
                        id="hotline"
                        name="hotline"
                        value={settings.hotline}
                        onChange={handleChange}
                        className={styles.formControl}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="address">Địa chỉ</label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={settings.address}
                        onChange={handleChange}
                        className={styles.formControl}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label>Liên kết mạng xã hội</label>
                    {settings.socialLink.map((link, index) => (
                        <div key={index} className={styles.socialLinkGroup}>
                            <input
                                type="text"
                                name="name"
                                value={link.name}
                                onChange={(e) => handleSocialLinkChange(index, e)}
                                placeholder="Tên mạng xã hội"
                                className={styles.formControl}
                            />
                            <input
                                type="url"
                                name="link"
                                value={link.link}
                                onChange={(e) => handleSocialLinkChange(index, e)}
                                placeholder="URL"
                                className={styles.formControl}
                            />
                        </div>
                    ))}
                </div>

                <button type="submit" className={styles.submitButton}>
                    Cập nhật cài đặt
                </button>
            </form>
        </div>
    );
};

export default Settings;
