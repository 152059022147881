import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import styles from './NotificationList.module.scss';
import Title from '~/components/Title';
import routes from '~/config/routes';
import { getNotifications, deleteNotification } from '~/services/notificationService';
import PushNotification from '~/components/PushNotification';

const NotificationInfoList = () => {
    const [notifications, setNotifications] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentNotification, setCurrentNotification] = useState(1);
    const [itemsPerNotification, setItemsPerNotification] = useState(10);
    const [notification, setNotification] = useState({ message: '', type: '' });

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const data = await getNotifications();
                if (data) {
                    setNotifications(data);
                } else {
                    setNotification({ message: 'Không có dữ liệu thông báo.', type: 'success' });
                }
            } catch (error) {
                console.error('Error fetching notifications:', error);
                setNotification({ message: 'Lỗi khi tải dữ liệu thông báo.', type: 'error' });
            }
        };

        fetchNotifications();
    }, []);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentNotification(1);
    };

    const handleDelete = async (id) => {
        try {
            await deleteNotification(id);
            setNotification({ message: 'Đã xóa thông báo thành công.', type: 'success' });
            setNotifications((prevNotifications) =>
                prevNotifications.filter((notification) => notification._id !== id),
            );
        } catch (error) {
            setNotification({ message: 'Lỗi khi xóa thông báo.', type: 'error' });
        }
    };

    const filteredNotifications = notifications.filter((notification) =>
        notification.message.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    const totalPages = Math.ceil(filteredNotifications.length / itemsPerNotification);

    return (
        <div className={styles.notificationContainer}>
            <Title className={styles.notificationTitle} text="Danh sách Thông báo" />
            {notification.message && <PushNotification message={notification.message} type={notification.type} />}
            <div className={styles.actionsContainer}>
                <input
                    type="text"
                    placeholder="Tìm kiếm Thông báo..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className={styles.searchInput}
                />
                <Link to={routes.addNotification} className={styles.addButton}>
                    <FontAwesomeIcon icon={faPlus} /> Thêm mới Thông báo
                </Link>
            </div>

            <div className={styles.notificationList}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Tiêu đề thông báo</th>
                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredNotifications.length > 0 ? (
                            filteredNotifications.map((notification, index) => (
                                <tr key={notification._id}>
                                    <td>{(currentNotification - 1) * itemsPerNotification + index + 1}</td>
                                    <td>{notification.message}</td>
                                    <td>
                                        <button
                                            onClick={() => handleDelete(notification._id)}
                                            className={styles.deleteButton}
                                        >
                                            <FontAwesomeIcon icon={faTrash} /> Xóa
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3">Không có dữ liệu</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className={styles.itemsPerNotificationContainer}>
                <label htmlFor="itemsPerNotification">Số mục mỗi trang:</label>
                <select
                    id="itemsPerNotification"
                    value={itemsPerNotification}
                    onChange={(e) => {
                        setItemsPerNotification(Number(e.target.value));
                        setCurrentNotification(1);
                    }}
                    className={styles.itemsPerNotificationSelect}
                >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>

            <div className={styles.pagination}>
                <span>
                    Hiện {(currentNotification - 1) * itemsPerNotification + 1} đến{' '}
                    {Math.min(currentNotification * itemsPerNotification, filteredNotifications.length)} của{' '}
                    {filteredNotifications.length}
                </span>
                <div className={styles.paginationControls}>
                    <button
                        onClick={() => setCurrentNotification((prev) => Math.max(prev - 1, 1))}
                        disabled={currentNotification === 1}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <button
                        onClick={() => setCurrentNotification((prev) => Math.min(prev + 1, totalPages))}
                        disabled={currentNotification === totalPages || totalPages === 0}
                    >
                        <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotificationInfoList;
