import httpRequest from '~/utils/httpRequest';

export const getLinks = async () => {
    try {
        const response = await httpRequest.get('/link');
        return response.data.data;
    } catch (error) {
        console.error('Error fetching links', error);
        throw error;
    }
};

export const getLinksPaginnation = async () => {
    try {
        const response = await httpRequest.get('/link');
        return response.data.data;
    } catch (error) {
        console.error('Error fetching links', error);
        throw error;
    }
};

export const createLink = async (linkData) => {
    try {
        const response = await httpRequest.post('/link', linkData);
        return response.data.data;
    } catch (error) {
        console.error('Error adding link', error);
        throw error;
    }
};

export const updateLink = async (linkId, updatedData) => {
    try {
        const response = await httpRequest.put(`/link/${linkId}`, updatedData);
        return response.data.data;
    } catch (error) {
        console.error('Error updating link', error);
        throw error;
    }
};

export const deleteLink = async (linkId) => {
    try {
        const response = await httpRequest.delete(`/link/${linkId}`);
        return response.data.data;
    } catch (error) {
        console.error('Error deleting link', error);
        throw error;
    }
};
