import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { createLink } from '~/services/linkService';
import PushNotification from '~/components/PushNotification';
import styles from './AddLink.module.scss';
import routes from '~/config/routes';
import Title from '~/components/Title';

const AddLink = () => {
    const navigate = useNavigate();
    const [notification, setNotification] = useState({ message: '', type: '' });

    const initialValues = {
        url: '',
        name: '',
    };

    const validationSchema = Yup.object({
        url: Yup.string().required('URL là bắt buộc').url('URL không hợp lệ'),
        name: Yup.string().required('Tên liên kết là bắt buộc'),
    });

    const handleSubmit = async (values, { resetForm }) => {
        try {
            await createLink(values);
            setNotification({ message: 'Thêm liên kết thành công!', type: 'success' });
            resetForm();
            setTimeout(() => {
                navigate(routes.linkList);
            }, 1000);
        } catch (error) {
            setNotification({ message: 'Lỗi khi thêm liên kết.', type: 'error' });
            console.error('Lỗi khi tạo liên kết:', error);
        }
    };

    return (
        <div className={styles.addLink}>
            <Title text="Thêm liên kết" />
            {notification.message && <PushNotification message={notification.message} type={notification.type} />}
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                    <Form className={styles.form}>
                        <div className={styles.formGroup}>
                            <label htmlFor="url">URL liên kết</label>
                            <Field
                                type="text"
                                id="url"
                                name="url"
                                placeholder="Nhập URL liên kết"
                                className={styles.input}
                            />
                            <ErrorMessage name="url" component="div" className={styles.error} />
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="name">Tên liên kết</label>
                            <Field
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Nhập tên liên kết"
                                className={styles.input}
                            />
                            <ErrorMessage name="name" component="div" className={styles.error} />
                        </div>

                        <button type="submit" disabled={isSubmitting} className={styles.submitButton}>
                            {isSubmitting ? <div className={styles.spinner}></div> : 'Thêm liên kết'}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddLink;
