import httpRequest from '~/utils/httpRequest';

export const getCustomers = async () => {
    try {
        const response = await httpRequest.get('/customer');
        return response.data.data;
    } catch (error) {
        console.error('Error fetching customers:', error);
        throw error;
    }
};

export const getCustomerById = async (id) => {
    try {
        const response = await httpRequest.get(`/customer/${id}`);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching customer:', error);
        throw error;
    }
};

export const createCustomer = async (customerData) => {
    try {
        const response = await httpRequest.post('/customer', customerData);
        return response.data.data;
    } catch (error) {
        console.error('Error creating customer:', error);
        throw error;
    }
};

export const updateCustomer = async (id, customerData) => {
    try {
        const response = await httpRequest.put(`/customer/${id}`, customerData);
        return response.data.data;
    } catch (error) {
        console.error('Error updating customer:', error);
        throw error;
    }
};

export const deleteCustomer = async (id) => {
    try {
        const response = await httpRequest.delete(`/customer/${id}`);
        return response.data.data;
    } catch (error) {
        console.error('Error deleting customer:', error);
        throw error;
    }
};
