import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot } from '@fortawesome/free-solid-svg-icons';
import Title from '~/components/Title';
import styles from './Link.module.scss';
import classNames from 'classnames/bind';
import { getLinks } from '~/services/linkService';

const cx = classNames.bind(styles);

const Links = () => {
    const [links, setLinks] = useState([]);

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const linksData = await getLinks();
                setLinks(linksData);
            } catch (error) {
                console.error('Error fetching links:', error);
            }
        };

        fetchLinks();
    }, []);

    const items = links.map((link) => ({
        label: (
            <Link to={link.url} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faCircleDot} style={{ marginRight: '8px', color: 'var(--primary)' }} />
                {link.name}
            </Link>
        ),
        key: link._id,
    }));

    return (
        <div className={cx('wrapper')}>
            <Title text="Liên kết trang" />
            <div className={cx('list-link')}>
                <Menu mode="vertical" items={items} style={{ borderRight: 0 }} />
            </div>
        </div>
    );
};

export default Links;
