import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Services.module.scss';
import Title from '~/components/Title';
import routes from '~/config/routes';
import { getCategoriesByType } from '~/services/categoryService';
import { Link } from 'react-router-dom';
import LoadingScreen from '~/components/LoadingScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const cx = classNames.bind(styles);

const Services = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const fetchedCategories = await getCategoriesByType(1);
                setCategories(fetchedCategories);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching categories:', error);
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    if (loading) {
        return <LoadingScreen isLoading={loading} />;
    }

    return (
        <div className={cx('wrapper')}>
            <div className={cx('inner')}>
                {/* <Title text="Dịch vụ" showSeeAll={true} slug={routes.services} /> */}
                <div className={cx('services-grid')}>
                    {categories.slice(0, 6).map((category) => (
                        <Link
                            key={category._id}
                            to={`${routes.services}/${category.slug}`}
                            className={cx('service-item')}
                        >
                            <div className={cx('image-container')}>
                                <img
                                    className={cx('image')}
                                    src={`${process.env.REACT_APP_HOST}/uploads/${category.image}`}
                                    alt="service"
                                />
                            </div>
                            <div className={cx('service-content')}>
                                <h3 className={cx('service-title')}>{category.name}</h3>
                                <p className={cx('service-description')}>{category.description}</p>
                                <div className={cx('see-more')}>
                                    <Link to={`${routes.services}/${category.slug}`} className={cx('see-more-button')}>
                                        Xem thêm
                                        <FontAwesomeIcon icon={faArrowRight} className={cx('arrow-icon')} />
                                    </Link>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Services;
