import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Title from '~/components/Title';
import styles from './NotificationDetail.module.scss';
import PushNotification from '~/components/PushNotification';
import LoadingScreen from '~/components/LoadingScreen';
import { getNotificationById } from '~/services/notificationService';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const cx = classNames.bind(styles);

const NotificationDetail = () => {
    const { id } = useParams();
    const [notificationContent, setNotificationContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    useEffect(() => {
        const fetchNotificationContent = async () => {
            try {
                if (id) {
                    const data = await getNotificationById(id);
                    setNotificationContent(data);
                } else {
                    setError(new Error('No id provided'));
                }
            } catch (error) {
                setError(error);
                console.error('Error fetching notification content:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNotificationContent();
    }, [id]);

    if (error) {
        const errorMessage = error.response ? error.response.data.message : 'Network Error';
        return <PushNotification message={errorMessage} />;
    }

    if (loading) {
        return <LoadingScreen />;
    }

    if (!notificationContent) {
        return <PushNotification message="No content available" />;
    }

    const pdfUrl = notificationContent.file ? `${process.env.REACT_APP_HOST}/${notificationContent.file}` : null;

    return (
        <article className={cx('wrapper')}>
            <Helmet>
                <title>{notificationContent.message} | TDC DAKLAK</title>
                <meta
                    name="description"
                    content="Trung tâm Kỹ thuật Tiêu chuẩn Đo lường Chất lượng tỉnh Đắk Lắk là đơn vị được chỉ định cung cấp các dịch vụ Kiểm định, Hiệu chuẩn, Thử nghiệm phương tiện đo, Phân tích thử nghiệm chất lượng sản phẩm hàng hóa phục vụ quản lý nhà nước và nhu cầu cho các tổ chức, cá nhân trong và ngoài tỉnh."
                />
                <meta name="keywords" content=" TDC DAKLAK, thông báo" />
                <meta name="author" content="TDC DAKLAK" />
            </Helmet>
            <div className={cx('inner')}>
                <Title text={notificationContent.message} />
                {pdfUrl && (
                    <div className={cx('pdf-viewer')}>
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                            <Viewer
                                fileUrl={pdfUrl}
                                plugins={[defaultLayoutPluginInstance]}
                                initialNotification={0}
                                // defaultScale={1.4}
                            />
                        </Worker>
                    </div>
                )}
            </div>
        </article>
    );
};

export default NotificationDetail;
