import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faChevronRight,
    faMapMarkerAlt,
    faPhoneVolume,
    faBlenderPhone,
    faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import classNames from 'classnames/bind';
import io from 'socket.io-client';
import { registerSendNews } from '~/services/registerNews';
import PushNotification from '~/components/PushNotification';
import styles from './Footer.module.scss';
import { getConfiguration } from '~/services/configurationService';

const cx = classNames.bind(styles);

const Footer = () => {
    const [stats, setStats] = useState({ online: 0, total: 0 });
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [config, setConfig] = useState(null);

    useEffect(() => {
        const socket = io(`${process.env.REACT_APP_HOST}`, {
            transports: ['websocket', 'polling'],
        });

        socket.on('connect', () => {
            console.log('Connected to server');
        });

        socket.on('connect_error', (error) => {
            console.error('Connection error:', error);
        });

        socket.on('stats', (data) => {
            setStats(data);
        });

        return () => socket.disconnect();
    }, []);

    useEffect(() => {
        const loadConfig = async () => {
            try {
                const response = await getConfiguration();
                setConfig(response);
            } catch (error) {
                console.error('Error loading configuration:', error);
            }
        };

        loadConfig();
    }, []);

    const handleSubscribe = async (email) => {
        try {
            setIsSubscribed(false);
            setErrorMessage('');

            await registerSendNews(email);

            setIsSubscribed(true);
            setErrorMessage('');
        } catch (error) {
            console.error('Đăng ký nhận bản tin thất bại:', error);
            setIsSubscribed(false);
            setErrorMessage('Đăng ký nhận bản tin thất bại, vui lòng thử lại sau.');
        }
    };

    if (!config) return null;

    return (
        <footer className={cx('wrapper')}>
            <div className={cx('inner')}>
                <div className={cx('footerColumnFull')}>
                    <div className={cx('footerFlexBetween')}>
                        <Link to="/">
                            <img
                                src={`${process.env.REACT_APP_HOST}/${config.logo}`}
                                alt="Company Logo"
                                className={cx('logo')}
                            />
                        </Link>
                        <div className={cx('footerMainContent')}>
                            <h5 className={cx('company-name')}>{config.name}</h5>
                            <div className={cx('onlineStatus')}>
                                <div className={cx('onlineStatusToday')}>
                                    <FontAwesomeIcon className={cx('footer-icon-dot')} icon={faCircle} />
                                    Đang online: <span className={cx('online-number')}>{stats.online}</span>
                                </div>
                                <div className={cx('onlineStatusTotal')}>
                                    <FontAwesomeIcon className={cx('footer-icon-dot')} icon={faCircle} />
                                    Tổng số lượt truy cập: <span className={cx('online-access')}>{stats.total}</span>
                                </div>
                            </div>
                            <p>
                                <FontAwesomeIcon className={cx('footer-icon')} icon={faMapMarkerAlt} />
                                {config.address}
                            </p>
                            <div className={cx('contactInfo')}>
                                <div className={cx('contactItem')}>
                                    <a className={cx('contactItemLink')} href={`tel:0${config.hotline}`}>
                                        <FontAwesomeIcon icon={faPhoneVolume} />
                                        <span>0{config.hotline}</span>
                                    </a>
                                </div>
                                <div className={cx('contactItem')}>
                                    <FontAwesomeIcon icon={faBlenderPhone} />
                                    {config.phone_number.map((phone, index) => (
                                        <React.Fragment key={index}>
                                            <a href={`tel:0${phone}`} className={cx('contactItemLink')}>
                                                0{phone}
                                            </a>
                                            {index < config.phone_number.length - 1 && (
                                                <span className={cx('contactItemLine')}> - </span>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className={cx('contactItem')}>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                    <span>{config.contact_email}</span>
                                </div>
                                {config.socialLink.map((social) => (
                                    <div className={cx('contactItem')} key={social._id}>
                                        {social.name.includes('Fanpage') && (
                                            <a className={cx('contactItemLink')} href={social.link}>
                                                <FontAwesomeIcon icon={faFacebook} />
                                                <span>{social.name}</span>
                                            </a>
                                        )}
                                        {social.name.includes('ZALO') && (
                                            <a className={cx('contactItemLink')} href={social.link}>
                                                <div className={cx('zaloIcon')}></div>
                                                <span>{social.name}</span>
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <form
                            className={cx('formGroup')}
                            onSubmit={(e) => {
                                e.preventDefault();
                                const email = e.target.email.value;
                                handleSubscribe(email);
                            }}
                        >
                            <label>Đăng ký nhận bản tin điện tử</label>
                            <div className={cx('formRow')}>
                                <input name="email" placeholder="Nhập địa chỉ email.." />
                                <button className={cx('foot-button')} type="submit">
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={cx('footerLinkCert')}>
                    <div className={cx('footerColumnHaft')}>
                        <h4 className={cx('footerTitle')}>LIÊN KẾT TỚI TRANG</h4>
                        <div className={cx('footerRowLink')}>
                            <ul className={cx('footerColumnHaft')}>
                                <li>
                                    <Link to="http://www.most.gov.vn/">Bộ Khoa học & Công nghệ</Link>
                                </li>
                                <li>
                                    <Link to={`http://www.mic.gov.vn/`}>Bộ Thông tin và Truyền thông</Link>
                                </li>
                                <li>
                                    <Link to={`http://mt.gov.vn/`}>Bộ Giao thông vận tải</Link>
                                </li>
                                <li>
                                    <Link to={`http://www.monre.gov.vn/`}>Bộ Tài nguyên và Môi trường</Link>
                                </li>
                                <li>
                                    <Link to={`http://www.moc.gov.vn/`}>Bộ Xây Dựng</Link>
                                </li>
                                <li>
                                    <Link to={`https://www.boa.gov.vn/`}>VP Công nhận Chất lượng</Link>
                                </li>
                            </ul>
                            <ul className={cx('footerColumnHaft')}>
                                <li>
                                    <Link to="http://www.mard.gov.vn/">Bộ Nông nghiệp & PT nông thôn</Link>
                                </li>
                                <li>
                                    <Link to={`http://moh.gov.vn/`}>Bộ Y tế</Link>
                                </li>
                                <li>
                                    <Link to={`http://www.molisa.gov.vn/`}>Bộ Lao động - Thương binh và Xã hội</Link>
                                </li>
                                <li>
                                    <Link to={`http://www.moit.gov.vn/`}>Bộ Công Thương</Link>
                                </li>
                                <li>
                                    <Link to={`http://www.tcvn.gov.vn/`}>Tổng cục TCĐLCL</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={cx('footerColumnHaft')}>
                        <h4 className={cx('footerTitle')}>HOẠT ĐỘNG CỦA TTKT ĐƯỢC CÔNG NHẬN BỞI</h4>
                        <div className={cx('footerRow')}>
                            <div className={cx('footerCert2')}></div>
                            <div className={cx('footerCert1')}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx('bottomBar')}>
                <p>
                    &copy; Copyright {config.name}. Thiết kế bởi{' '}
                    <a href="https://www.takatech.com.vn/" target="_blank" rel="noopener noreferrer">
                        Takatech
                    </a>
                </p>
            </div>
            {errorMessage && <PushNotification message={errorMessage} type="error" />}
            {isSubscribed && !errorMessage && (
                <PushNotification message="Bạn đã đăng ký nhận bản tin thành công!" type="success" />
            )}
        </footer>
    );
};

export default Footer;
