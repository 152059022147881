import httpRequest from '~/utils/httpRequest';

export const getNotifications = async () => {
    try {
        const response = await httpRequest.get(`/notification`);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching notification content:', error);
        throw error;
    }
};

export const addNotificationContent = async (data) => {
    try {
        const response = await httpRequest.post(`/notification`, data);
        return response.data.data;
    } catch (error) {
        console.error('Error adding notification content:', error);
        throw error;
    }
};

export const updateNotificationContent = async (id, data) => {
    try {
        const response = await httpRequest.patch(`/notification/${id}`, data);
        return response.data.data;
    } catch (error) {
        console.error('Error updating notification content by id:', error);
        throw error;
    }
};

export const getNotificationById = async (id) => {
    try {
        const response = await httpRequest.get(`/notification/${id}`);
        return response.data.data;
    } catch (error) {
        console.error('Error updating notification content:', error);
        throw error;
    }
};

export const deleteNotification = async (id) => {
    try {
        const response = await httpRequest.delete(`/notification/${id}`);
        return response.data.data;
    } catch (error) {
        console.error('Error deleting notification content:', error);
        throw error;
    }
};
