import React, { useEffect, useState, useCallback } from 'react';
import { getConfiguration } from '~/services/configurationService';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import styles from './Banner.module.scss';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCircle } from '@fortawesome/free-solid-svg-icons';

const cx = classNames.bind(styles);

const Banner = () => {
    const [slides, setSlides] = useState([]);
    const [configs, setConfigs] = useState([]);

    const fetchSlides = useCallback(async () => {
        try {
            const configData = await getConfiguration();
            const sliderData = configData.homepage_slider;
            setSlides(sliderData);
            setConfigs(configData);
        } catch (error) {
            console.error('Error fetching slides:', error);
        }
    }, []);

    useEffect(() => {
        fetchSlides();
    }, [fetchSlides]);

    if (slides.length === 0) {
        return null;
    }

    return (
        <div className={cx('banner')}>
            <div className={cx('inner')}>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    modules={[Autoplay, Navigation, EffectFade]}
                    effect="fade"
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    speed={1500}
                    navigation={{
                        nextEl: `.${cx('swiper-button-next')}`,
                        prevEl: `.${cx('swiper-button-prev')}`,
                    }}
                    observer={true}
                    observeParents={true}
                    className={cx('swiper')}
                >
                    {slides.map((slide, index) => (
                        <SwiperSlide key={index} className={cx('slide')}>
                            <div className={cx('image-card')}>
                                <img
                                    src={`${process.env.REACT_APP_HOST}/${slide.image_url}`}
                                    alt={slide.title}
                                    className={cx('image')}
                                />
                                {slide.title && (
                                    <div className={cx('contentContainer', slide.position)}>
                                        <div className={cx('textWrapper')}>
                                            <span className={cx('text')}>{slide.title}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </SwiperSlide>
                    ))}
                    <div className={cx('swiper-button-prev')}>
                        <FontAwesomeIcon icon={faChevronLeft} className={cx('swiper-icon')} />
                    </div>
                    <div className={cx('swiper-button-next')}>
                        <FontAwesomeIcon icon={faChevronRight} className={cx('swiper-icon')} />
                    </div>
                </Swiper>
                <div className={cx('hotline')}>
                    <div className={cx('marquee')}>
                        <p className={cx('marquee-center')}>
                            <FontAwesomeIcon
                                icon={faCircle}
                                style={{ margin: '0 8px 0', color: 'var(--white)', fontSize: '8px' }}
                            />
                            Hotline:
                            <a className={cx('phone-number')} href="tel:0977717177">
                                0{configs.hotline}
                            </a>
                            <FontAwesomeIcon
                                icon={faCircle}
                                style={{ margin: '0 8px 0', color: 'var(--white)', fontSize: '8px' }}
                            />
                            Số liên hệ:
                            {configs.phone_number.map((phone, index) => (
                                <React.Fragment key={index}>
                                    <a className={cx('phone-number')} href={`tel:0${phone}`}>
                                        0{phone}
                                    </a>
                                    {index < configs.phone_number.length - 1 && ' - '}
                                </React.Fragment>
                            ))}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
