const routes = {
    //Admin
    admin: '/admin',
    dashboard: '/admin/dashboard',
    navigationList: '/admin/navigation-list',
    addNavigation: '/admin/add-navigation',
    editNavigation: '/admin/edit-navigation/:id',
    productList: '/admin/product-list',
    addProduct: '/admin/add-product',
    editProduct: '/admin/edit-product/:id',
    newsList: '/admin/news-list',
    addNews: '/admin/add-news',
    updateNews: '/admin/update-news/:id',
    recruitmentList: '/admin/recruitment-list',
    addRecruitment: '/admin/add-recruitment',
    updateRecruitment: '/admin/update-recruitment/:id',
    serviceList: '/admin/service-list',
    addService: '/admin/add-service',
    updateService: '/admin/update-service/:id',
    partnerList: '/admin/partner-list',
    addPartner: '/admin/add-partner',
    customerList: '/admin/customer-list',
    addCustomer: '/admin/add-customer',
    adsList: '/admin/ads-list',
    addAds: '/admin/add-ads',
    updateProject: '/admin/update-project/:id',
    projectList: '/admin/project-list',
    addProject: '/admin/add-project',
    updateLegal: '/admin/update-legal/:id',
    legalList: '/admin/legal-list',
    addLegal: '/admin/add-legal',
    updateUser: '/admin/update-user/:id',
    changePassword: '/admin/change-password',
    userList: '/admin/user-list',
    addUser: '/admin/add-user',
    videosList: '/admin/videos-list',
    imagesList: '/admin/images-list',
    addImage: '/admin/add-image',
    addVideo: '/admin/add-video',
    linkList: '/admin/link-list',
    addLink: '/admin/add-link',
    messagesList: '/admin/messages-list',
    settings: '/admin/settings',
    pageList: '/admin/page-list',
    addPage: '/admin/add-page',
    updatePage: '/admin/update-page',
    categoryList: '/admin/category-list',
    addCategory: '/admin/add-category',
    updateCategory: '/admin/update-category/:id',
    departmentList: '/admin/department-list',
    addDepartment: '/admin/add-department',
    memberList: '/admin/member-list',
    addMember: '/admin/add-member',
    updateMember: '/admin/update-member/:id/:departmentId',
    notificationList: '/admin/notification-list',
    addNotification: '/admin/add-notification',
    //User
    home: '/',
    about: '/:slug',
    introduction: '/gioi-thieu/:slug',
    products: '/san-pham',
    productCategory: '/san-pham/:slug',
    productDetail: '/san-pham/:category/:id',
    news: '/tin-tuc',
    newsDetail: `/tin-tuc/:category/:id`,
    newsCategory: `/tin-tuc/:slug`,
    recruitment: '/tuyen-dung',
    recruitmentDetail: `/tuyen-dung/:category/:id`,
    recruitmentCategory: `/tuyen-dung/:slug`,
    search: '/search',
    projects: '/du-an',
    projectCategory: '/du-an/:slug',
    projectDetail: '/du-an/:category/:id',
    services: '/dich-vu',
    serviceDetail: '/dich-vu/:id',
    serviceDocument: '/dich-vu/:slug',
    // servicesCategory: `/dich-vu/:slug`,
    legal: '/van-ban-phap-luat',
    legalDetail: '/van-ban-phap-luat/:category/:id',
    notificationDetail: '/thong-bao/:id',
    notification: '/thong-bao',
    legalCategory: `/van-ban-phap-luat/:slug`,
    teams: '/doi-ngu',
    error404: '/404',
    contact: '/lien-he',
};

export default routes;
