import httpRequest from '~/utils/httpRequest';

export const registerSendNews = async (mail) => {
    try {
        const formData = new FormData();
        formData.append('mail', mail);

        const response = await httpRequest.post('/mail', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data.data;
    } catch (error) {
        console.error('Error post register news', error);
        throw error;
    }
};
