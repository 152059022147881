import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { addNotificationContent } from '~/services/notificationService';
import PushNotification from '~/components/PushNotification';
import styles from './AddNotification.module.scss';
import routes from '~/config/routes';
import { useNavigate } from 'react-router-dom';
import Title from '~/components/Title';

const validationSchema = Yup.object({
    title: Yup.string().required('Hãy nhập tên thông báo'),
    attachments: Yup.mixed().test('fileType', 'Định dạng không hỗ trợ', (value) => true),
});

const AddNotification = () => {
    const [notification, setNotification] = useState({ message: '', type: '' });
    const navigate = useNavigate();

    const initialValues = {
        title: '',
        attachments: '',
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const formData = new FormData();
            formData.append('message', values.title);

            if (values.attachments && values.attachments instanceof File) {
                formData.append('file', values.attachments);
            }

            await addNotificationContent(formData, true);
            resetForm();
            setNotification({ message: 'Đã thêm thông báo thành công!', type: 'success' });
            setTimeout(() => {
                navigate(routes.notificationList);
            }, 1000);
        } catch (error) {
            console.error('Error adding notification content:', error);
            setNotification({ message: 'Lỗi khi thêm thông báo', type: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className={styles.addNotification}>
            <Title text="Thêm mới thông báo" />
            {notification.message && <PushNotification message={notification.message} type={notification.type} />}
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form className={styles.form}>
                        <div className={styles.formGroup}>
                            <label htmlFor="title">Tiêu đề thông báo</label>
                            <Field name="title" type="text" className={styles.input} />
                            <ErrorMessage name="title" component="div" className={styles.error} />
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="attachments">Tải lên file PDF</label>
                            {values.attachments && (
                                <p>
                                    Current file:{' '}
                                    <strong>
                                        {values.attachments instanceof File
                                            ? values.attachments.name
                                            : values.attachments}
                                    </strong>
                                </p>
                            )}
                            <input
                                id="attachments"
                                name="attachments"
                                type="file"
                                accept="application/pdf"
                                onChange={(event) => {
                                    setFieldValue('attachments', event.currentTarget.files[0]);
                                }}
                                className={styles.input}
                            />
                            <ErrorMessage name="attachments" component="div" className={styles.error} />
                        </div>
                        <div className={styles.formGroup}>
                            <button type="submit" className={styles.submitButton} disabled={isSubmitting}>
                                {isSubmitting ? 'Đang thêm...' : 'Thêm Thông báo'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddNotification;
