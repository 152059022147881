import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import Card from '~/components/CardContent';
import SuggestCard from '~/components/SuggestCard';
import { getServices } from '~/services/serviceService';
import styles from './Service.module.scss';
import ButtonGroup from '~/components/ButtonGroup';
import PushNotification from '~/components/PushNotification';
import LoadingScreen from '~/components/LoadingScreen';
import routes from '~/config/routes';
import { getCategoriesByType } from '~/services/categoryService';
import { Helmet } from 'react-helmet';
import { getNewsPagination } from '~/services/newsService';

const cx = classNames.bind(styles);

const Service = () => {
    const [serviceItems, setServiceItems] = useState([]);
    const [newsData, setNewsData] = useState([]);
    const [newsCategories, setNewsCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedSuggestion, setSelectedSuggestion] = useState(0);

    useEffect(() => {
        const fetchCategoriesAndService = async () => {
            try {
                const categoriesNews = await getCategoriesByType(4);
                setNewsCategories(categoriesNews);

                const newsData = await getNewsPagination(1, 10);
                const serviceData = await getServices();

                setNewsData(newsData.news);
                setServiceItems(serviceData.service);
            } catch (error) {
                setError(error);
                console.error('Error fetching service:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategoriesAndService();
    }, []);

    const handleButtonClick = (index) => {
        setSelectedSuggestion(index);
    };

    const getCategorySlug = (categoryId) => {
        const category = newsCategories.find((category) => categoryId === category._id);
        return category ? category.slug : '';
    };

    if (error) {
        const errorMessage = error.response ? error.response.data.message : 'Network Error';
        return <PushNotification message={errorMessage} />;
    }

    if (loading) {
        return <LoadingScreen />;
    }

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    let filteredNewsItems = newsData;
    if (selectedSuggestion === 0) {
        filteredNewsItems = shuffleArray([...newsData]);
    } else if (selectedSuggestion === 1) {
        filteredNewsItems = newsData.filter((item) => item.views > 10);
    }
    filteredNewsItems = filteredNewsItems.slice(0, 5);

    return (
        <article className={cx('wrapper')}>
            <Helmet>
                <title>Dịch vụ | TDC DAKLAK</title>
                <meta
                    name="description"
                    content="Trung tâm Kỹ thuật Tiêu chuẩn Đo lường Chất lượng tỉnh Đắk Lắk là đơn vị được chỉ định cung cấp các dịch vụ Kiểm định, Hiệu chuẩn, Thử nghiệm phương tiện đo, Phân tích thử nghiệm chất lượng sản phẩm hàng hóa phục vụ quản lý nhà nước và nhu cầu cho các tổ chức, cá nhân trong và ngoài tỉnh."
                />
                <meta name="keywords" content="dịch vụ, cập nhật, TDC DAKLAK" />
            </Helmet>
            <div className={cx('service-section')}>
                <div className={cx('service-column')}>
                    <h2 className={cx('service-title')}>Tất cả dịch vụ</h2>
                    <div className={cx('service-grid')}>
                        {serviceItems.map((item, index) => (
                            <Link key={index} to={`${routes.services}/${item._id}`} className={cx('service-item')}>
                                <Card
                                    title={item.name}
                                    summary={item.summary}
                                    image={`${process.env.REACT_APP_HOST}/${item.image}`}
                                    createdAt={item.createdAt}
                                    views={item.views}
                                />
                            </Link>
                        ))}
                    </div>
                </div>

                {/* Phần suggest giữ nguyên */}
                <div className={cx('suggest')}>
                    <h2 className={cx('suggest-title')}>Có thể bạn quan tâm</h2>
                    <ButtonGroup buttons={['Xem nhiều', 'Nổi bật']} onButtonClick={handleButtonClick} />
                    <div className={cx('suggest-items')}>
                        {filteredNewsItems.map((item, index) => (
                            <Link key={index} to={`${routes.news}/${getCategorySlug(item.categoryId)}/${item._id}`}>
                                <SuggestCard
                                    title={item.title}
                                    summary={item.summary}
                                    image={`${process.env.REACT_APP_HOST}/${item.images}`}
                                    createdAt={item.createdAt}
                                    views={item.views}
                                />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </article>
    );
};

export default Service;
