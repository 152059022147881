// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wbTw0cc8J8uKajvpI1sc{width:100%}.xJhfq9Ar8275Ol9glZbn{display:flex;align-items:center;justify-content:center}.Yvl66lz7DH6fKyVODN6G{width:var(--default-layout-width);display:grid;grid-template-columns:calc(75% - 10px) calc(25% - 10px);gap:20px;margin-top:30px}@media(max-width: 1024px){.Yvl66lz7DH6fKyVODN6G{width:100%;grid-template-columns:100%;padding:0 20px;margin-top:0}}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.scss"],"names":[],"mappings":"AAGA,sBACI,UAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CAGJ,sBACI,iCAAA,CACA,YAAA,CACA,uDAAA,CACA,QAAA,CACA,eAAA,CAAA,0BALJ,sBAQQ,UAAA,CACA,0BAAA,CACA,cAAA,CACA,YAAA,CAAA","sourcesContent":["@import '../../styles/mixins';\n@import '../../styles/variables';\n\n.wrapper {\n    width: 100%;\n}\n\n.wrapperContent {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.gridContent {\n    width: var(--default-layout-width);\n    display: grid;\n    grid-template-columns: calc(75% - 10px) calc(25% - 10px);\n    gap: 20px;\n    margin-top: 30px;\n\n    @include respond-to(lg) {\n        width: 100%;\n        grid-template-columns: 100%;\n        padding: 0 20px;\n        margin-top: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wbTw0cc8J8uKajvpI1sc`,
	"wrapperContent": `xJhfq9Ar8275Ol9glZbn`,
	"gridContent": `Yvl66lz7DH6fKyVODN6G`
};
export default ___CSS_LOADER_EXPORT___;
