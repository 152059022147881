import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { getCustomers, deleteCustomer } from '~/services/customerService';
import styles from './CustomerList.module.scss';
import Title from '~/components/Title';
import routes from '~/config/routes';
import PushNotification from '~/components/PushNotification';

const CustomerList = () => {
    const [customers, setCustomers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [notification, setNotification] = useState({ message: '', type: '' });

    useEffect(() => {
        const fetchCustomers = async () => {
            const data = await getCustomers();
            if (data) {
                setCustomers(data);
            } else {
                setNotification({ message: 'Có lỗi khi tải dữ liệu Khách hàng!', type: 'error' });
            }
        };

        fetchCustomers();
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm('Bạn có chắc chắn muốn xóa Khách hàng này?')) {
            try {
                await deleteCustomer(id);
                setCustomers(customers.filter((customer) => customer._id !== id));
                setNotification({ message: 'Khách hàng đã được xóa thành công!', type: 'success' });
            } catch (error) {
                console.error('Error deleting customer:', error);
                setNotification({ message: 'Đã xảy ra lỗi khi xóa Khách hàng!', type: 'error' });
            }
        }
    };

    const filteredCustomers = customers.filter((customer) => customer._id.toString().includes(searchTerm));

    const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage);
    const indexOfLastCustomer = currentPage * itemsPerPage;
    const indexOfFirstCustomer = indexOfLastCustomer - itemsPerPage;
    const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

    return (
        <div className={styles.customerContainer}>
            <Title className={styles.pageTitle} text="Danh sách Khách hàng" />
            {notification.message && <PushNotification message={notification.message} type={notification.type} />}
            <div className={styles.actionsContainer}>
                <input
                    type="text"
                    placeholder="Tìm kiếm Khách hàng..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={styles.searchInput}
                />
                <Link to={routes.addCustomer} className={styles.addButton}>
                    <FontAwesomeIcon icon={faPlus} /> Thêm mới Khách hàng
                </Link>
            </div>

            <div className={styles.customerList}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Số thứ tự</th>
                            <th>Logo</th>

                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentCustomers.length > 0 ? (
                            currentCustomers.map((customer, index) => (
                                <tr key={customer._id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <img
                                            src={`${process.env.REACT_APP_HOST}/uploads/${customer.logo}`}
                                            alt={`Customer ${customer.id}`}
                                            className={styles.customerImage}
                                        />
                                    </td>

                                    <td>
                                        <button
                                            onClick={() => handleDelete(customer._id)}
                                            className={styles.deleteButton}
                                        >
                                            <FontAwesomeIcon icon={faTrash} /> Xóa
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3">Không có dữ liệu</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className={styles.itemsPerPageContainer}>
                <label htmlFor="itemsPerPage">Số mục mỗi trang:</label>
                <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={(e) => {
                        setItemsPerPage(Number(e.target.value));
                        setCurrentPage(1);
                    }}
                    className={styles.itemsPerPageSelect}
                >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>

            <div className={styles.pagination}>
                <span>
                    Hiện {indexOfFirstCustomer + 1} đến {Math.min(indexOfLastCustomer, filteredCustomers.length)} của{' '}
                    {filteredCustomers.length}
                </span>
                <div className={styles.paginationControls}>
                    <button
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <button
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomerList;
