import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { getActivityByCategory } from '~/services/activityService';
import Title from '~/components/Title';
import styles from './RecruitmentCategory.module.scss';
import { Link } from 'react-router-dom';
import Card from '~/components/CardContent/CardContent';
import { getCategoriesByType } from '~/services/categoryService';
import routes from '~/config/routes';
import { Helmet } from 'react-helmet';
import { Empty } from 'antd';

const cx = classNames.bind(styles);

function RecruitmentCategory() {
    const location = useLocation();
    const [recruitment, setRecruitment] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const recruitmentPerPage = 6;

    const extractSlugFromPathname = (pathname) => {
        const parts = pathname.split('/');
        return parts.length > 2 ? parts[2] : null;
    };

    const slug = extractSlugFromPathname(location.pathname);

    useEffect(() => {
        async function fetchCategory() {
            try {
                const categories = await getCategoriesByType(5);
                const category = categories.find((cat) => cat.slug === slug);
                if (category) {
                    setCategoryId(category._id);
                    setCategoryName(category.name);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        }

        if (slug) {
            fetchCategory();
        }
    }, [slug]);

    useEffect(() => {
        async function fetchRecruitmentCategory() {
            if (categoryId) {
                try {
                    const data = await getActivityByCategory(categoryId, currentPage, recruitmentPerPage);

                    setRecruitment(
                        data.actions.map((recruitmentItem) => ({
                            ...recruitmentItem,
                        })),
                    );
                    setTotalPages(data.totalPages);
                } catch (error) {
                    console.error('Error fetching recruitment:', error);
                }
            }
        }

        fetchRecruitmentCategory();
    }, [categoryId, currentPage]);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const renderRecruitmentCategory = () => {
        if (recruitment.length === 0) {
            return (
                <>
                    <div />
                    <Empty description="Đang cập nhật..." />
                    <div />
                </>
            );
        }

        return recruitment.map((recruitmentItem) => (
            <Link to={`${routes.recruitment}/${slug}/${recruitmentItem._id}`} key={recruitmentItem._id}>
                <Card
                    title={recruitmentItem.title}
                    image={recruitmentItem.images}
                    summary={recruitmentItem.summary}
                    createdAt={recruitmentItem.createdAt}
                    views={recruitmentItem.views}
                    isNew={recruitmentItem.isNew}
                />
            </Link>
        ));
    };

    const renderPagination = () => {
        return (
            <div className={cx('pagination')}>
                <div
                    className={cx('pageButton', { disabled: currentPage === 1 })}
                    onClick={() => handlePageChange(currentPage - 1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>
                {Array.from({ length: totalPages }, (_, index) => (
                    <div
                        key={index}
                        className={cx('pageButton', { active: currentPage === index + 1 })}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </div>
                ))}
                <div
                    className={cx('pageButton', { disabled: currentPage === totalPages })}
                    onClick={() => handlePageChange(currentPage + 1)}
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </div>
            </div>
        );
    };

    return (
        <div className={cx('container')}>
            <Helmet>
                <title>{categoryName} | TDC DAKLAK</title>
                <meta
                    name="description"
                    content="Trung tâm Kỹ thuật Tiêu chuẩn Đo lường Chất lượng tỉnh Đắk Lắk là đơn vị được chỉ định cung cấp các dịch vụ Kiểm định, Hiệu chuẩn, Thử nghiệm phương tiện đo, Phân tích thử nghiệm chất lượng sản phẩm hàng hóa phục vụ quản lý nhà nước và nhu cầu cho các tổ chức, cá nhân trong và ngoài tỉnh."
                />
                <meta name="keywords" content={`${categoryName}, hoạt động, TDC DAKLAK`} />
            </Helmet>
            <Title text={categoryName} />

            <div className={cx('recruitmentGrid')}>{renderRecruitmentCategory()}</div>
            {renderPagination()}
        </div>
    );
}

export default RecruitmentCategory;
