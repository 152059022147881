import React from 'react';
import { Helmet } from 'react-helmet';
// import Overview from './Overview';
import Products from './Products';
// import Services from './Services';
import Projects from './Projects';
import NewsLibrary from './NewsLibrary';
import Banner from './Banner';
// import Teams from './Teams';
import Partners from './Partners';
import styles from './Home.module.scss';
import Services from './Services';
import Customer from './Customer';
import Links from './Links';
import Notification from './Notification';

const Home = () => {
    return (
        <article className={styles.wrapper}>
            <Helmet>
                <title>Trang Chủ | TDC DAKLAK </title>
                <meta
                    name="description"
                    content="Trung tâm Kỹ thuật Tiêu chuẩn Đo lường Chất lượng tỉnh Đắk Lắk là đơn vị được chỉ định cung cấp các dịch vụ Kiểm định, Hiệu chuẩn, Thử nghiệm phương tiện đo, Phân tích thử nghiệm chất lượng sản phẩm hàng hóa phục vụ quản lý nhà nước và nhu cầu cho các tổ chức, cá nhân trong và ngoài tỉnh."
                />
                <meta name="keywords" content="trung tâm kiểm định, TDC DAKLAK, tdcdaklak" />
                <meta name="author" content="TDC DAKLAK" />
            </Helmet>
            <Banner />
            <div className={styles.wrapperContent}>
                <div className={styles.gridContent}>
                    <div className={styles.mainContent}>
                        {/* <Overview /> */}
                        {/* <Products /> */}
                        <Services />
                        {/* <Projects /> */}
                        {/* <Teams /> */}
                    </div>
                    <div className={styles.rightContent}>
                        <Notification />
                        <Customer />
                        <Links />
                    </div>
                </div>
            </div>
            <NewsLibrary />
            <Partners />
        </article>
    );
};

export default Home;
