import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { getCategoriesByType } from '~/services/categoryService';
import PushNotification from '~/components/PushNotification';
import LoadingScreen from '~/components/LoadingScreen';
import { Link } from 'react-router-dom';
import routes from '~/config/routes';
import styles from './Notification.module.scss';
import { getNotifications } from '~/services/notificationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faCircle, faCircleDot, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DateTime from '~/components/DateTime';

const cx = classNames.bind(styles);

function Notification() {
    const [notification, setNotification] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadNotification = async () => {
            try {
                const notificationData = await getNotifications();
                console.log(notificationData);
                setNotification([...notificationData, ...notificationData, ...notificationData]);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        loadNotification();
    }, []);

    if (error) {
        const errorMessage = error.response ? error.response.data.message : 'Network Error';
        return <PushNotification message={errorMessage} />;
    }

    if (loading) {
        return <LoadingScreen />;
    }

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 2500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        vertical: true,
        verticalSwiping: true,
        pauseOnHover: false,
        arrows: false,
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('inner')}>
                <div className={cx('title')}>
                    <h3>Thông Báo</h3>
                </div>
                <Slider {...sliderSettings} className={cx('notification-slider')}>
                    {notification.slice(0, 10).map((notification, index) => (
                        <div key={index} className={cx('notification-item')}>
                            <Link to={`${routes.notification}/${notification._id}`} className={cx('notification-link')}>
                                <div className={cx('notification-link-main')}>
                                    <FontAwesomeIcon icon={faCircle} className={cx('icon')} />
                                    <div className={cx('notification-content')}>
                                        <h3 className={cx('notification-title')}>{notification.message}</h3>
                                    </div>
                                </div>
                                <span className={cx('notification-date')}>
                                    <DateTime
                                        timestamp={notification.createdAt}
                                        showDate={true}
                                        showTime={true}
                                        showViews={false}
                                        className={cx('notification-date-info')}
                                    />
                                </span>
                            </Link>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Notification;
